<script>
import {mapGetters, mapMutations} from "vuex";
import pokerCommon from "@/pokerCommon";

export default {
  name: "PlayerTurn",
  props: {
    player: {
      type: Object,
      required: true,
    },
    me: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      timer: null,
      size: {
        r: this.me ? 46 : 28,
        cx: this.me ? 50 : 30,
        cy: this.me ? 50 : 30,
      },
      isTimerSound: false,
      isInitialLoad: true,
      prevTotalLength: 0,
      prevCountdown: 0,
      prevOffset: 0,
    }
  },
  computed: {
    ...mapGetters(['maintenanceNotice', 'isOnline', 'isConnected', 'networkUnavailable', 'socketErrorCount']),
    ...mapGetters('game', ['remainSec', 'remainSecMs', 'roomInfo', 'gameInfo', 'gameStatus', 'isPaused', 'isGameOver', 'isPaused', 'isPlaying']),
    hasNetworkError() {
      return [!this.isOnline, !this.isConnected].every(status => status);
    },
    countdownDuration() {
      return this.gameInfo.playerActionTime || 10;
    },
    countdownTotalLength() {
      return 2 * Math.PI * this.size.r;
    },
    countdownOffset() {
      const remainingSeconds = this.remainSecMs / 1000; // 밀리초를 초로 변환
      return this.countdownTotalLength * (1 - remainingSeconds / this.countdownDuration);
    },
    progressStyle() {
      const transitionStyle = this.isInitialLoad || this.hasNetworkError
        ? {transition: 'none'} :
        {
          transition: 'stroke-dashoffset 1s linear'
        };

      const length = this.hasNetworkError ? this.prevTotalLength : this.countdownTotalLength;
      const offset = this.hasNetworkError ? this.prevOffset : this.countdownOffset;

      return {
        strokeDasharray: `${length}px`,
        strokeDashoffset: `${offset}px`,
        transitionStyle
      };
    },
    countdown() {
      return this.remainSec;
    },
  },
  watch: {
    value() {
      this.countdown === 0 && this.close();
    },
    countdown(v, _v) {
      if (this.countdown < 4 && !this.isTimerSound) {
        pokerCommon.playSound("countdown");
        this.isTimerSound = true;
      }

      this.prevCountdown = _v;
    },
    countdownTotalLength(v, _v) {
      this.prevTotalLength = _v;
    },
    countdownOffset(v, _v) {
      this.prevOffset = _v;
    }
  },
  methods: {
    ...mapMutations('game', {
      __setTurnCountdown: 'SET_TURN_COUNTDOWN'
    }),
    // updateTimer() {
    //   if (this.isPaused) {
    //     return;
    //   }
    // },
    close() {
      this.$nextTick(() => {
        this.$emit('countdown', false);
      });
    }
  },
  mounted() {
    this.isInitialLoad = false;
  },
  beforeDestroy() {
    // this.__setTurnCountdown(0);
    // clearInterval(this.timer);
  }
}
</script>

<template>
  <div class="wrap_player_countdown">
    <div class="player_countdown" :class="{ large: me }">
      <div class="countdown-number">{{ hasNetworkError ? prevCountdown : countdown }}</div>
      <svg>
        <circle
          :r="size.r"
          :cx="size.cx"
          :cy="size.cy"
          :style="progressStyle"
        >
        </circle>
      </svg>
    </div>
  </div>
</template>
